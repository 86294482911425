<template>
  <div>
    <ProjectDetailItemEditCard :confirmation-message="getConfirmationMessage"
                    title="Bewerk project afbeeldingen"
                    :update-store-action="getStoreAction" :update-store-action-payload="getPayload" 
                    :projectId="projectId" :clientId="clientId" :promises.sync="promises" :successCallback="getSuccessCallBack"
                    :hideSaveButton="!getSaveButtonContent">
      <template v-slot:form-fields>
        <!-- 
          This combobox is a workaround to use v-forms validation check, since v-item-group doesn't count as an input field
          It contains the selected images and causes the submit button to be disabled when rules aren't met
        -->
        <v-combobox v-model="getPayload.images" disabled multiple required :rules="[v => (!!v && v.length >= 1) || 'error']" class="d-none"></v-combobox>
        <v-item-group multiple v-model="selectedImages">
          <v-row v-if="images && images.length > 0" >
            <v-col cols="6" md="4" lg="3" xl="2" v-for="image in images" :key="image">
              <v-item v-slot="{ active, toggle }" :value="image">
                  <v-img :src="image + '?' + getSASKey" class="text-right clickable" contain aspect-ratio="1"  @click="() => onImageClick(image, toggle)">
                    <template v-slot:placeholder>
                        <v-sheet
                        class="rounded"
                        color="cultured-gray"
                        height="100%">
                            <v-skeleton-loader
                            transition="scale-transition"
                            height="100%"
                            class="mx-auto"
                            type="image"/>
                        </v-sheet>
                    </template>
                    <v-btn v-if="showCheckmarks" fab x-small :icon="!active" class="ma-1">
                      <v-icon :color="active ? 'secondary' : 'white'" x-large>{{ active ? 'mdi-check-circle-outline' : 'mdi-circle-outline' }}</v-icon>
                    </v-btn>
                  </v-img>
              </v-item>
            </v-col>
          </v-row>
          <v-row v-else class="d-flex flex-column flex-grow-1 w-100">
              <v-icon class="align-self-center flex-grow-1" size="110px">mdi-image-off-outline</v-icon>
              <div class="align-self-center">Er zijn geen afbeeldingen beschikbaar</div>
          </v-row>
        </v-item-group>
      </template>
      <template v-slot:buttons-left>
        <v-col cols="12">
          <v-pagination v-model="currentPage" :length="pageCount"></v-pagination>
        </v-col>
        <v-col cols="12" :sm="!getSaveButtonContent ? 12: 'auto'" class="d-flex align-center flex-grow-1">
          <v-row dense>
            <v-col :cols="option=='upload' ? 6 : 9" sm="6">
              <v-autocomplete v-model="option" :items="editOptions" hide-details solo dense>
                <template v-slot:item="itemAttrs">
                  <v-icon left>{{ itemAttrs.item.icon }}</v-icon>{{ itemAttrs.item.text }}
                </template>
                <template v-slot:selection="itemAttrs">
                  <v-icon left>{{ itemAttrs.item.icon }}</v-icon>{{ itemAttrs.item.text }}
                </template>
              </v-autocomplete>
            </v-col>
            <v-col :cols="option=='upload' ? 6 : 3" :sm="6" class="d-flex align-center">
              <v-btn v-if="showCheckmarks" class="w-100" @click="toggleSelected" color="secondary">
                <span class="hidden-sm-and-down">{{ allAreSelected ? 'Niets selecteren' : 'Pagina selecteren'}}</span>
                <v-icon :right="!$vuetify.breakpoint.smAndDown">{{ allAreSelected ? 'mdi-checkbox-multiple-blank-circle-outline' : 'mdi-checkbox-multiple-marked-circle-outline'}}</v-icon>
              </v-btn>
              <v-file-input v-if="option=='upload'" solo dense hide-details multiple counter prepend-icon="" prepend-inner-icon="$file" clearable class="mr-1" v-model="imagesToUpload" accept="image/*"></v-file-input>
            </v-col>
          </v-row>
        </v-col>
      </template>
      
      <template v-slot:save-button-content>
        {{ getSaveButtonContent }}
      </template>
    </ProjectDetailItemEditCard>
    <v-overlay :value="overlayImage" :z-index="500">
      <v-btn color="$fossil-gray"
             absolute
             dark
             top
             right
             class="mt-9"
             small
             depressed
             fab
             @click="closeOverlayImage">
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
      <v-img :src="overlayImage + '?' + getSASKey" max-width="100vw" max-height="100vh" contain/>
    </v-overlay>
  </div>
  
</template>
<script>
import ProjectDetailItemEditCard from "@/views/project/forms/ProjectDetailItemEditCard.vue"

export default {
    name: "ProjectDetailEditImages",
    props: {
      projectId: {
        type: String,
        required: true
      },
      clientId: {
        type: String,
        required: true
      }
    },
    components: {
      ProjectDetailItemEditCard
    },
    data() {
      return {
        selectedImages: [],
        images: [],
        pageCount: null,
        page: 1,
        imagesToUpload: null,
        promises: [
          this.$store.dispatch("fileExportModule/fetchSASKey")
            .then(result => this.sasKey = result),
        ],
        option: 'inspect',
        overlayImage: null,
        editOptions: [
          {
            text: 'Inspecteren',
            value: 'inspect',
            icon: 'mdi-image-search-outline'
          },
          {
            text: 'Uploaden',
            value: 'upload',
            icon: 'mdi-upload'
          },
          {
            text: 'Downloaden',
            value: 'download',
            icon: 'mdi-download'
          },
          {
            text: 'Verwijderen',
            value: 'delete',
            icon: 'mdi-trash-can-outline'
          }
        ],
        sasKey: null,
        icon: "mdi-close"
      }
    },
    created() {
      this.currentPage = Number(this.$route.query.page || 1)
      this.promises.push(this.fetchImages(this.currentPage, this.$route.query.count))
    },
  mounted() {
    document.addEventListener('keyup', (evt) =>{
      if (evt.keyCode === 27){
        this.closeOverlayImage()
      }
    })
  },
    computed: {
      getConfirmationMessage() {
        switch (this.option) {
          case 'upload':
            return 'Project afbeeldingen werden succesvol geupload'
          case 'delete':
            return 'Project afbeeldingen werden succesvol verwijderd'
          case 'download':
          case 'inspect':
          default:
            return ''
        }
      },
      getStoreAction() {
        switch (this.option) {
          case 'upload':
            return 'projectsModule/uploadImages'
          case 'download':
            return 'projectsModule/downloadImages'
          case 'delete':
            return 'projectsModule/deleteImages'
          case 'inspect':
          default:
            return ''
        }
      },
      getPayload() {
        switch (this.option) {
          case 'upload':
            return {clientId: this.clientId, projectId: this.projectId, images: this.imagesToUpload}
          case 'download':
            return {images: this.selectedImages, projectId: this.projectId, clientId: this.clientId, sasKey: this.getSASKey}
          case 'delete':
          case 'inspect':
          default:
            return {images: this.selectedImages, projectId: this.projectId, clientId: this.clientId}
        }
      },
      getSuccessCallBack() {
        switch (this.option) {
          case 'delete':
            return () => {
              this.selectedImages = []
              this.pushPage(this.currentPage)
            }
          case 'upload':
            return () => {
              this.imagesToUpload = null
              this.pushPage(this.currentPage)
            }
          case 'inspect':
          case 'download':
          default:
            return () => {}
        }
      },
      showCheckmarks() {
        switch (this.option) {
          case 'download':
          case 'delete':
            return true
          case 'inspect':
          case 'upload':
          default:
            return false
        }
      },
      allAreSelected() {
        return this.images?.length == this.selectedImages?.length
      },
      getSASKey() {
        return this.$store.getters["fileExportModule/getSASKey"] || this.sasKey
      },
      currentPage: {
        get() {
          return Math.max(1, Math.min(this.page, this.pageCount))
        },
        set(newValue) {
          this.page = Math.max(1, Math.min(newValue, this.pageCount))
        }
      },
      getSaveButtonContent() {
        switch (this.option) {
          case 'download':
            return 'Download'
          case 'delete':
            return 'Verwijder'
          case 'upload':
            return 'Upload'
          case 'inspect':
          default:
            return ''
        }
      }
    }, 
    methods: {
      onImageClick(image, toggle) {
        switch (this.option) {
          case 'upload':
          case 'inspect':
            return this.toggleImageOverlay(image)
          case 'download':
          case 'delete':
          default:
            return toggle()
        }
      },
      toggleImageOverlay(image) {
        if (this.overlayImage) {
          this.closeOverlayImage()
        } else {
          this.overlayImage = image
        }
      },
      toggleSelected() {
        if (this.allAreSelected) {
          this.selectedImages = []
        } else {
          this.selectedImages = this.images;
        }
      },
      fetchImages(page, count) {
        return this.$store.dispatch("projectsModule/fetchImages", {clientId: this.clientId, projectId: this.projectId, page: page - 1, count: count})
          .then(result => {
            this.images = result.dtoList
            this.pageCount = result.pageCount
          })
      },
      pushPage(page) {
        if (page != this.$route.query.page) {
          this.$router.push({query: {...this.$route.query, page: page}})
        }
        this.promises.push(
          this.fetchImages(page, this.$route.query.count)
        )
      },
      closeOverlayImage(){
        this.overlayImage = null
      }
    },
    watch: {
      currentPage(newValue) {
        this.selectedImages = []
        this.pushPage(newValue)
      },
    }
}
</script>
<style lang="scss" scoped>
// remove the darkner background effect of vuetify
  ::v-deep .theme--light.v-skeleton-loader .v-skeleton-loader__image {
    background: none
  }
</style>